<template>
  <div class="end-wrap">
    <div class="content">
      <img src="~@/assets/image/paperimg.png" class="end-pic" alt="END" />
      <p style="margin-top: 20px">{{ $t('exam.all_approved') }}</p>
      <!-- 全部已批阅 -->
      <a-button type="primary">
        <router-link to="/mine#exam-review">{{ $t('XB_Finish') }}</router-link>
        <!-- 完成 -->
      </a-button>
    </div>
  </div>
</template>
<style lang="less" scoped>
.end-wrap {
  .content {
    height: calc(100vh - 255px);
    padding-top: 50px;
    .mixinFlex(unset; center; column);
    .end-pic {
      height: 390px;
    }
  }
}
</style>
